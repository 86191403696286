@import "../variables";

body {
    *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    *::-webkit-scrollbar-button {
        display: none;
    }

    *::-webkit-scrollbar-thumb {
        background: var(--grey-medium);
        border-radius: 5px;

        &:horizontal {
            display: none;
        }
    }

    *::-webkit-scrollbar-track {
        background: linear-gradient(180deg, rgba(240, 240, 240, 0) 0%, var(--grey-light) 100%);

        &:horizontal {
            display: none;
            background: linear-gradient(270deg, rgba(240, 240, 240, 0) 0%, var(--grey-light) 100%);
        }
    }

    *::-webkit-scrollbar-track-piece {
        background: transparent;
        border-radius: 5px;
    }

    .scrollbar--none {
        scrollbar-color: unset;
        scrollbar-width: none;

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-thumb {
            display: none;
        }

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }
    }

    @supports (scrollbar-color: auto) {
        html {
            scrollbar-width: thin;
            scrollbar-color: var(--grey-medium) var(--grey-light);
        }
    }
}

@media (min-width: @size-bp-s) {
    body {
        *::-webkit-scrollbar-thumb {
            &:horizontal {
                display: block;
            }
        }

        *::-webkit-scrollbar-track {
            &:horizontal {
                display: block;
            }
        }
    }
}
