.mButton {
  display: block;
  width:100%;
  position: relative;
  font-family: @mont;
  font-weight: 500;
  border: 0;
  line-height: 0;
  text-align: center;
  color: @white;
  cursor: pointer;
  outline: 0;
  zoom: 1;
  transition: all 0.2s ease-in-out;
  &.mat-button {
    display: block;
  }
  &.mw-s {
    display: block;
  }
  &.mw-m {
    display: none;
  }
  .mButton-text {
    position: relative;
  }

  &__line {
    color: @gray2;
    background:transparent;
    padding:0 10px;
    border: 2px solid @gray2;
    &.mat-button {
      border: 2px solid @gray2;
    }
    &.c_red { // red
      color:@red;
      background:transparent;
      border-color:@red;
      &:hover {
        color:@white;
        background:@red;
        border-color:@red;
      }
      &:active {
        color:@white;
        background:@red;
        border-color:@red;
      }
    }
    &.c_sky { // sky
      color:@sky;
      background:transparent;
      border-color:@sky;
      &:hover {
        color:@sky;
        background:transparent;
        border-color:@sky;
      }
      &:active {
        color:@sky;
        background:transparent;
        border-color:@sky;
      }
    }
    &.c_blue { // blue
      color:@blue;
      background:transparent;
      border-color:@blue;
      &:hover {
        color:@blue;
        background:transparent;
        border-color:@blue;
      }
      &:active {
        color:@blue;
        background:transparent;
        border-color:@blue;
      }
    }
    &.c_yellow { // yellow
      color:#DAB758;
      background:transparent;
      border-color:#DAB758;
      &:hover {
        color:#DAB758;
        background:transparent;
        border-color:#DAB758;
      }
      &:active {
        color:#DAB758;
        background:transparent;
        border-color:#DAB758;
      }
    }
    &.c_gray { // gray
      color:#C4C4C4;
      background:transparent;
      border-color:#C4C4C4;
      &:hover {
        color:#C4C4C4;
        background:transparent;
        border-color:#C4C4C4;
      }
      &:active {
        color:#C4C4C4;
        background:transparent;
        border-color:#C4C4C4;
      }
    }
    &.c_white { // white
      color:@white;
      background:transparent;
      border-color:@white;
      &:hover {
        color:@white;
        background:transparent;
        border-color:@white;
      }
      &:active {
        color:@white;
        background:transparent;
        border-color:@white;
      }
    }
    &:disabled,
    &.disabled,
    &.c_disabled {
      color:#E7E7E7;
      background:transparent;
      border-color:#E7E7E7;
      cursor: default;
      &:hover {
        color:#E7E7E7;
        background:transparent;
        border-color:#E7E7E7;
      }
      &:active {
        color:#E7E7E7;
        background:transparent;
        border-color:#E7E7E7;
      }
    }

    &.s-s_30 {
      border-radius: 20px;
      height: 30px;
      border-width: 1px!important;

      .mButton-text {
        line-height: 28px;
      }
    }
    &.s-s_36 {
      border-radius: 20px;
      height: 36px;
      .mButton-text {
        line-height: 32px;
      }
    }
    &.s-s_40 {
      border-radius: 20px;
      height: 40px;
      .mButton-text {
        line-height: 36px;
      }
    }
    &.s-s_45 {
      border-radius: 25px;
      height: 45px;
      .mButton-text {
        line-height: 41px;
      }
    }
    &.s-s_50 {
      border-radius: 25px;
      height: 50px;
      .mButton-text {
        line-height: 46px;
      }
    }

    .mButton-text {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: bold;
    }
    .f-s_10 {
      font-size: 10px;
    }
    .f-s_11 {
      font-size: 11px;
    }
    .f-s_12 {
      font-size: 12px;
    }
    .f-s_14 {
      font-size: 14px;
    }
  }
  &__full {
    color: @white;
    background: @gray2;
    padding:0 10px;
    border: 2px solid @gray2;
    &.mat-button {
      border: 2px solid @gray2;
    }
    &.c_red {
      color:@white;
      background:@red;
      border-color:@red;
      &:hover {
        color:@white;
        background:@red;
        border-color:@red;
      }
      &:active {
        color:@white;
        background:@red;
        border-color:@red;
      }
    }
    &.c_sky { // sky
      color:@white;
      background:@sky;
      border-color:@sky;
      &:hover {
        color:@white;
        background:@sky;
        border-color:@sky;
      }
      &:active {
        color:@white;
        background:@sky;
        border-color:@sky;
      }
    }
    &.c_blue { // blue
      color:@white;
      background:@blue;
      border-color:@blue;
      &:hover {
        color:@white;
        background:@blue;
        border-color:@blue;
      }
      &:active {
        color:@white;
        background:@blue;
        border-color:@blue;
      }
    }
    &.c_yellow { // yellow
      color:@white;
      background:#DAB758;
      border-color:#DAB758;
      &:hover {
        color:@white;
        background:#DAB758;
        border-color:#DAB758;
      }
      &:active {
        color:@white;
        background:#DAB758;
        border-color:#DAB758;
      }
    }
    &.c_gray { // gray
      color:@white;
      background:#C4C4C4;
      border-color:#C4C4C4;
      &:hover {
        color:@white;
        background:#C4C4C4;
        border-color:#C4C4C4;
      }
      &:active {
        color:@white;
        background:#C4C4C4;
        border-color:#C4C4C4;
      }
    }
    &.c_white { // white
      color:@black;
      background:@white;
      border-color:@white;
      &:hover {
        color:@black;
        background:@white;
        border-color:@white;
      }
      &:active {
        color:@black;
        background:@white;
        border-color:@white;
      }
    }
    &:disabled,
    &.disabled,
    &.c_disabled {
      color:#CCCCCC;
      background:#E7E7E7;
      border-color:#E7E7E7;
      cursor: default;
      &:hover {
        color:#CCCCCC;
        background:#E7E7E7;
        border-color:#E7E7E7;
      }
      &:active {
        color:#CCCCCC;
        background:#E7E7E7;
        border-color:#E7E7E7;
      }
    }

    &.s-s_30 {
      border-radius: 20px;
      height: 30px;
      border-width: 1px!important;
      .mButton-text {
        line-height: 28px;
      }
    }
    &.s-s_40 {
      border-radius: 20px;
      height: 40px;
      .mButton-text {
        line-height: 36px;
      }
    }
    &.s-s_36 {
      border-radius: 20px;
      height: 36px;
      .mButton-text {
        line-height: 32px;
      }
    }
    &.s-s_45 {
      border-radius: 25px;
      height: 45px;
      .mButton-text {
        line-height: 41px;
      }
    }
    &.s-s_50 {
      border-radius: 25px;
      height: 50px;
      .mButton-text {
        line-height: 46px;
      }
    }

    .mButton-text {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: bold;
    }
    .f-s_10 {
      font-size: 10px;
    }
    .f-s_11 {
      font-size: 11px;
    }
    .f-s_12 {
      font-size: 12px;
    }
    .f-s_14 {
      font-size: 14px;
    }
  }

  @media (min-width: @mw-m-up) {

      &.mw-s {
        display: none;
      }
      &.mw-m {
        display: block;
      }

      &__line {
        &.s-m_30 {
          border-radius: 20px;
          height: 30px;
          border-width: 1px!important;

          .mButton-text {
            line-height: 28px;
          }
        }
        &.s-m_35 {
          border-radius: 20px;
          height: 35px;
          .mButton-text {
            line-height: 31px;
          }
        }
        &.s-m_40 {
          border-radius: 20px;
          height: 40px;
          .mButton-text {
            line-height: 36px;
          }
        }
        &.s-m_45 {
          border-radius: 25px;
          height: 45px;
          .mButton-text {
            line-height: 41px;
          }
        }
        &.s-m_50 {
          border-radius: 25px;
          height: 50px;
          .mButton-text {
            line-height: 46px;
          }
        }

        .f-m_10 {
          font-size: 10px;
        }
        .f-m_11 {
          font-size: 11px;
        }
        .f-m_12 {
          font-size: 12px;
        }
        .f-m_14 {
          font-size: 14px;
        }
      }
      &__full {
        &.s-m_30 {
          border-radius: 20px;
          height: 30px;
          border-width: 1px!important;

          .mButton-text {
            line-height: 28px;
          }
        }
        &.s-m_35 {
          border-radius: 20px;
          height: 35px;
          .mButton-text {
            line-height: 31px;
          }
        }
        &.s-m_40 {
          border-radius: 20px;
          height: 40px;
          .mButton-text {
            line-height: 36px;
          }
        }
        &.s-m_45 {
          border-radius: 25px;
          height: 45px;
          .mButton-text {
            line-height: 41px;
          }
        }
        &.s-m_50 {
          border-radius: 25px;
          height: 50px;
          .mButton-text {
            line-height: 46px;
          }
        }

        .f-m_10 {
          font-size: 10px;
        }
        .f-m_11 {
          font-size: 11px;
        }
        .f-m_12 {
          font-size: 12px;
        }
        .f-m_14 {
          font-size: 14px;
        }
      }
  }
}
