@import "../variables";

// Style dialog
.cdk-overlay-container {
    .cdk-dialog-container {
        &:focus-visible {
            outline: none!important;
        }
    }

}
.cdk-global-scrollblock {
    overflow-y: hidden;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.7) !important;
}

.cdk-overlay-pane, .mat-dialog-container, .mdc-dialog__surface {
    border-radius: 10px !important;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3) !important;
}

.cdk-overlay-pane-new {
    margin: @offset-wrapper-s;
    background-color: var(--white);
    overflow: hidden;
    max-height: calc(100% - 32px);;
    .cdk-dialog-container {
        outline: none!important;
        overflow-x: auto;
        height: auto;
    }
}

.cdk-overlay-pane-gallery {
    margin: @offset-wrapper-s;
    background-color: var(--white);
    overflow: hidden;
    max-height: calc(100% - 32px);
    height: 100%;
    .cdk-dialog-container {
        outline: none!important;
        position: relative;
        margin: @offset-wrapper-s;
    }
}

.mat-dialog-container, .mat-mdc-dialog-surface {
    padding: 16px !important;
}

//Класс если нужно диалок окно на всю ширину и высоту
.mat-dialog-full {
    border-radius: 0 !important;

    .mat-dialog-container, .mat-mdc-dialog-surface {
        border-radius: 0 !important;
        padding: 0 !important;
    }
}

@media (min-width: @size-bp-s) {
    .cdk-overlay-container {
        height: 100%;
    }
}

@media (min-width: @size-bp-m) {

    .cdk-overlay-pane, .mat-dialog-container, .mdc-dialog__surface {
        border-radius: 20px !important;
    }

    .mat-dialog-container, .mdc-dialog__surface {
        padding: 24px !important;
    }
}
