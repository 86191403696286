@import "../mixin/a";

//a {
//    cursor: pointer;
//    color: var(--color-primary);
//    text-decoration: dashed;
//    &:hover {
//        text-decoration: none;
//    }
//}
//a[href] {
//    text-decoration: underline;
//    &:hover {
//        text-decoration: none;
//    }
//}
//a[href^=skype] {
//    color: inherit;
//    text-decoration: none;
//    cursor: default;
//}
//
//a[href^=tel] {
//    color: inherit;
//    text-decoration: none;
//    cursor: pointer;
//}
//
//a[href^=skype] {
//    color: inherit;
//    text-decoration: none;
//    cursor: default;
//}


.link {
    cursor: pointer;
    color: var(--color-text-primary);
}

.link_underline {
    .setUnderline();
}

.link_dashed {
    .setDashed();
}

.link_grey-dark {
    color: var(--grey-dark);
}

.link_red {
    color: var(--color-primary);
}
