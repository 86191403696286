:root {
  --white: #fff;
  --black: #000;
  --black20: #0003;
  --red: #f63f3f;
  --pink-dark: #ff6868;
  --pink: #fa8c8c;
  --pink-light: #ffc7c7;
  --grey-dark: #707070;
  --grey: #dadada;
  --grey-light: #f0f0f0;
  --grey-medium: #bbb;
  --blue: #68abc1;
  --blue-light: #96cee0;
  --black-light: #2a2a2a;
  --yellow-dark: #dab758;
  --yellow-medium: #efcb69;
  --yellow-light: #fff5c1;
  --green-stock-index: #88ab3f;
  --green-stock-index-light: #b0d267;
  --color-primary: var(--red);
  --color-text-primary: var(--black);
  --color-text-secondary: var(--grey-dark);
  --color-link-primary: var(--red);
  --color-bg-primary: var(--white);
  --color-bg-secondary: var(--grey-light);
  --color-error-primary: var(--red);
  --color-warning-primary: var(--yellow-light);
  --color-info-primary: var(--red);
  --color-index-light-red: #f16232;
  --color-index-yellow: #f6ae42;
  --size-font: 15px;
  --size-line-height: 1.5;
  --font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif;
  --size-bp-xs: 320px;
  --size-bp-s: 768px;
  --size-bp-m: 1000px;
  --size-bp-l: 1240px;
  --size-bp-xl: 1920px;
  --size-fix: var(--size-bp-xs);
  --size-fix2: 300px;
}
/* montserrat-300 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.eot');
  /* IE9 Compat Modes */
  src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.woff2') format('woff2'), /* Super Modern Browsers */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.woff') format('woff'), /* Modern Browsers */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
/* montserrat-500 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.eot');
  /* IE9 Compat Modes */
  src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.woff2') format('woff2'), /* Super Modern Browsers */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.woff') format('woff'), /* Modern Browsers */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
/* montserrat-600 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.eot');
  /* IE9 Compat Modes */
  src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.woff2') format('woff2'), /* Super Modern Browsers */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.woff') format('woff'), /* Modern Browsers */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
/* montserrat-700 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.eot');
  /* IE9 Compat Modes */
  src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.woff2') format('woff2'), /* Super Modern Browsers */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.woff') format('woff'), /* Modern Browsers */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.svg#Montserrat') format('svg');
  /* Legacy iOS */
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  outline: none;
}
body {
  font-size: 0;
  line-height: 1.4;
  font-weight: 500;
}
@media (min-device-width: 320px) and (max-device-width: 1000px) {
  body {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-focus-ring-color: rgba(255, 255, 255, 0);
    outline: none;
    resize: none;
    text-size-adjust: none;
  }
}
ol,
ul {
  list-style: none;
}
sup {
  vertical-align: super;
}
sub {
  vertical-align: sub;
}
strong {
  font-weight: bold;
}
input,
textarea,
select,
button {
  outline: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-family: inherit;
}
textarea {
  resize: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  max-width: 100%;
}
button {
  background-color: transparent;
  cursor: pointer;
}
nav {
  display: block;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  outline: none;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dibt {
  display: inline-block;
  zoom: 1;
  vertical-align: top;
}
.dibm {
  display: inline-block;
  zoom: 1;
  vertical-align: middle;
}
.wrap {
  margin-right: auto;
  margin-left: auto;
}
.all {
  display: table;
  width: 100%;
}
.nobr {
  white-space: pre;
}
input::-webkit-search-cancel-button {
  display: none;
}
em {
  font-style: italic;
}
body *::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
body *::-webkit-scrollbar-button {
  display: none;
}
body *::-webkit-scrollbar-thumb {
  background: var(--grey-medium);
  border-radius: 5px;
}
body *::-webkit-scrollbar-thumb:horizontal {
  display: none;
}
body *::-webkit-scrollbar-track {
  background: linear-gradient(180deg, rgba(240, 240, 240, 0) 0%, var(--grey-light) 100%);
}
body *::-webkit-scrollbar-track:horizontal {
  display: none;
  background: linear-gradient(270deg, rgba(240, 240, 240, 0) 0%, var(--grey-light) 100%);
}
body *::-webkit-scrollbar-track-piece {
  background: transparent;
  border-radius: 5px;
}
body .scrollbar--none {
  scrollbar-color: unset;
  scrollbar-width: none;
}
body .scrollbar--none::-webkit-scrollbar,
body .scrollbar--none::-webkit-scrollbar-track,
body .scrollbar--none::-webkit-scrollbar-thumb {
  display: none;
}
body .scrollbar--none::-webkit-scrollbar {
  width: 0;
  height: 0;
}
body .scrollbar--none::-webkit-scrollbar-track {
  background: transparent;
}
@supports (scrollbar-color: auto) {
  body html {
    scrollbar-width: thin;
    scrollbar-color: var(--grey-medium) var(--grey-light);
  }
}
@media (min-width: 768px) {
  body *::-webkit-scrollbar-thumb:horizontal {
    display: block;
  }
  body *::-webkit-scrollbar-track:horizontal {
    display: block;
  }
}
.wrapper {
  min-width: 300px;
  padding: 0 16px;
  margin: 0 auto;
}
.wrapper_desktop {
  padding: 0;
}
@media (min-width: 768px) {
  .wrapper_desktop {
    padding: 0 16px;
  }
}
@media (min-width: 1240px) {
  .wrapper {
    max-width: 1272px;
    padding: 0 16px;
  }
}
.cdk-overlay-container .cdk-dialog-container:focus-visible {
  outline: none!important;
}
.cdk-global-scrollblock {
  overflow-y: hidden;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7) !important;
}
.cdk-overlay-pane,
.mat-dialog-container,
.mdc-dialog__surface {
  border-radius: 10px !important;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3) !important;
}
.cdk-overlay-pane-new {
  margin: 16px;
  background-color: var(--white);
  overflow: hidden;
  max-height: calc(100% - 32px);
}
.cdk-overlay-pane-new .cdk-dialog-container {
  outline: none!important;
  overflow-x: auto;
  height: auto;
}
.cdk-overlay-pane-gallery {
  margin: 16px;
  background-color: var(--white);
  overflow: hidden;
  max-height: calc(100% - 32px);
  height: 100%;
}
.cdk-overlay-pane-gallery .cdk-dialog-container {
  outline: none!important;
  position: relative;
  margin: 16px;
}
.mat-dialog-container,
.mat-mdc-dialog-surface {
  padding: 16px !important;
}
.mat-dialog-full {
  border-radius: 0 !important;
}
.mat-dialog-full .mat-dialog-container,
.mat-dialog-full .mat-mdc-dialog-surface {
  border-radius: 0 !important;
  padding: 0 !important;
}
@media (min-width: 768px) {
  .cdk-overlay-container {
    height: 100%;
  }
}
@media (min-width: 1000px) {
  .cdk-overlay-pane,
  .mat-dialog-container,
  .mdc-dialog__surface {
    border-radius: 20px !important;
  }
  .mat-dialog-container,
  .mdc-dialog__surface {
    padding: 24px !important;
  }
}
.shadow-button {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}
.shadow-frame {
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1), 0 -3px 5px rgba(0, 0, 0, 0.15);
}
.shadow-card {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.2s ease-in-out;
}
.shadow-card:hover {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.5);
}
.shadow-header-map {
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1), 0 -3px 5px rgba(0, 0, 0, 0.15);
}
shadow-tabbar {
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1), 0 -3px 5px rgba(0, 0, 0, 0.15);
}
svg-icon {
  display: inline-block;
  line-height: 0;
}
.skeleton {
  background-color: var(--grey-light);
  background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) var(--grey-light);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1.4s skeleton ease-in-out infinite;
  color: transparent !important;
}
.skeleton * {
  color: transparent !important;
  background-color: transparent !important;
  opacity: 0;
}
.skeleton--text {
  border-radius: 4px;
}
.skeleton--box {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.skeleton--box::before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes skeleton {
  to {
    background-position-x: -20%;
  }
}
.link {
  cursor: pointer;
  color: var(--color-text-primary);
}
.link_underline {
  text-decoration: underline;
  text-underline-offset: 1px;
}
.link_underline:hover {
  text-decoration: none;
}
.link_dashed {
  text-decoration-style: dashed;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 1px;
}
.link_dashed:hover {
  text-decoration: none;
}
.link_grey-dark {
  color: var(--grey-dark);
}
.link_red {
  color: var(--color-primary);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 16px;
}
h1 {
  font-size: 15px;
}
h2 {
  font-size: 15px;
}
h3,
h4,
h5,
h6 {
  font-size: 13px;
}
@media (min-width: 768px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 24px;
  }
  h1 {
    font-size: 30px;
    margin-bottom: 32px;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 15px;
  }
}
p {
  margin-bottom: 16px;
}
p:last-child {
  margin-bottom: 0;
}
p:only-child {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  p {
    margin-bottom: 24px;
  }
}
body {
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif;
  width: 100%;
  background: var(--white);
  color: var(--color-text-primary);
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
}
::selection {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}
html {
  min-height: 100vh;
}
html body {
  min-height: 100vh;
  position: relative;
}
section {
  width: 100%;
}
a {
  cursor: pointer;
}
a[href^=tel] {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
a[href^=skype] {
  color: inherit;
  text-decoration: none;
  cursor: default;
}
b,
strong {
  font-weight: bold;
}
i {
  font-style: italic;
}
span.break {
  white-space: pre;
}
.wrap {
  width: 320px;
  padding-right: 10px;
  padding-left: 10px;
}
.wrap-s {
  width: 320px;
  padding-right: 10px;
  padding-left: 10px;
  margin: 0 auto;
}
.wrap-m {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin: 0;
}
.hddn {
  display: none;
  opacity: 0;
  visibility: hidden;
}
.mw-s {
  display: block;
}
.mw-m {
  display: none;
}
.br-s {
  display: block;
}
.htext-s {
  display: inline;
}
.htext-m {
  display: none;
}
.blue__bg {
  background: #4ca2e4 !important;
}
.yellow__bg {
  background: #f3990a !important;
}
.pink__bg {
  background: #ce71b9 !important;
}
.green__bg {
  background: #00732d !important;
}
.lgreen__bg {
  background: #26b84b !important;
}
.non__bg {
  background: transparent!important;
}
@media (min-width: 1000px) {
  html#desktop #wrapper {
    height: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    position: relative;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  html#desktop #bottom {
    height: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    position: relative;
  }
  html#desktop #mm {
    display: none;
  }
  html#desktop .mw-s {
    display: none;
  }
  html#desktop .mw-m {
    display: block;
  }
  html#desktop .br-s {
    display: inline-block;
    vertical-align: top;
  }
  html#desktop .br-m {
    display: block;
  }
  html#desktop .htext-s {
    display: none;
  }
  html#desktop .htext-m {
    display: inline;
  }
  html#desktop .wrap {
    width: 1260px;
    padding-right: 10px;
    padding-left: 10px;
  }
  html#desktop .wrap-s {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin: 0;
  }
  html#desktop .wrap-m {
    width: 1260px;
    padding-right: 10px;
    padding-left: 10px;
    margin: 0 auto;
  }
  html#desktop .gray__bg {
    background: #e7e7e7 !important;
  }
  html#desktop .dgray__bg {
    background: #dcdcdc !important;
  }
  html#desktop .non__bg {
    background: transparent!important;
  }
}
input {
  -webkit-appearance: none !important;
  border-radius: 0;
  line-height: normal;
  font-size: 16px;
}
input:focus {
  font-size: 16px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}
input.form {
  width: 100%;
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif, sans-serif;
  color: #41434c;
  font-size: 30px;
  line-height: 1;
  padding: 4px 10px 3px;
  background: #fff;
  border-radius: 5px;
  border: 3px solid #c2c2c2;
  text-align: center;
}
input.form::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #838383;
  text-align: center;
  line-height: normal;
}
input.form::-moz-placeholder {
  opacity: 1;
  color: #838383;
  text-align: center;
  line-height: normal;
}
input.form:-moz-placeholder {
  opacity: 1;
  color: #838383;
  text-align: center;
  line-height: normal;
}
input.form:-ms-input-placeholder {
  opacity: 1;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #838383;
  text-align: center;
  line-height: normal;
}
input.form:hover {
  border-color: #a6e2d8;
}
input.form:focus {
  border-color: #a6e2d8;
}
input.form.error {
  color: #f63f3f !important;
  border-color: #f63f3f;
}
input.form.error::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #f63f3f;
  text-align: center;
  line-height: normal;
}
input.form.error::-moz-placeholder {
  opacity: 1;
  color: #f63f3f;
  text-align: center;
  line-height: normal;
}
input.form.error:-moz-placeholder {
  opacity: 1;
  color: #f63f3f;
  text-align: center;
  line-height: normal;
}
input.form.error:-ms-input-placeholder {
  opacity: 1;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #f63f3f;
  text-align: center;
  line-height: normal;
}
input.form6 {
  width: 100%;
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif, sans-serif;
  font-weight: 500;
  color: #404040;
  font-size: 13px;
  line-height: 1;
  padding: 9px 14px 9px;
  border: 1px solid #f0f0f0;
  transition: all 0.3s ease-in-out;
  background: #f0f0f0;
  border-radius: 6px;
  max-height: 36px;
}
input.form6::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.form6::-moz-placeholder {
  opacity: 1;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.form6:-moz-placeholder {
  opacity: 1;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.form6:-ms-input-placeholder {
  opacity: 1;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.form6:active {
  border-color: #d4d4d4;
  background: #fff;
}
input.form6:focus {
  border-color: #d4d4d4;
  background: #fff;
}
input.form6.error {
  border-color: #f63f3f !important;
  color: #f63f3f !important;
}
input.form6.error::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.form6.error::-moz-placeholder {
  opacity: 1;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.form6.error:-moz-placeholder {
  opacity: 1;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.form6.error:-ms-input-placeholder {
  opacity: 1;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
@media (min-width: 1000px) {
  input.form6 {
    font-size: 15px;
    padding: 10px 17px;
    border: 1px solid #f0f0f0;
    background: #f0f0f0;
    border-radius: 6px;
    max-height: 40px;
  }
  input.form6::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #707070;
    text-align: left;
    line-height: normal;
  }
  input.form6::-moz-placeholder {
    opacity: 1;
    color: #707070;
    text-align: left;
    line-height: normal;
  }
  input.form6:-moz-placeholder {
    opacity: 1;
    color: #707070;
    text-align: left;
    line-height: normal;
  }
  input.form6:-ms-input-placeholder {
    opacity: 1;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #707070;
    text-align: left;
    line-height: normal;
  }
  input.form6:active {
    border-color: #d4d4d4;
    background: #fff;
  }
  input.form6:focus {
    border-color: #d4d4d4;
    background: #fff;
  }
  input.form6.error {
    border-color: #f63f3f !important;
    color: #f63f3f !important;
  }
  input.form6.error::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #f63f3f;
    text-align: left;
    line-height: normal;
  }
  input.form6.error::-moz-placeholder {
    opacity: 1;
    color: #f63f3f;
    text-align: left;
    line-height: normal;
  }
  input.form6.error:-moz-placeholder {
    opacity: 1;
    color: #f63f3f;
    text-align: left;
    line-height: normal;
  }
  input.form6.error:-ms-input-placeholder {
    opacity: 1;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #f63f3f;
    text-align: left;
    line-height: normal;
  }
}
input.form8 {
  width: 100%;
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif, sans-serif;
  color: #404040;
  font-size: 13px;
  line-height: 1;
  padding: 9px 14px 9px;
  border: 1px solid #f0f0f0;
  transition: all 0.3s ease-in-out;
  background: #f0f0f0;
  border-radius: 6px;
  max-height: 36px;
}
input.form8::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #404040;
  text-align: left;
  line-height: normal;
}
input.form8::-moz-placeholder {
  opacity: 1;
  color: #404040;
  text-align: left;
  line-height: normal;
}
input.form8:-moz-placeholder {
  opacity: 1;
  color: #404040;
  text-align: left;
  line-height: normal;
}
input.form8:-ms-input-placeholder {
  opacity: 1;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #404040;
  text-align: left;
  line-height: normal;
}
input.form8:active {
  border-color: #b9b9b9;
}
input.form8:focus {
  border-color: #b9b9b9;
}
input.form8.error {
  border-color: #f63f3f !important;
  color: #f63f3f !important;
}
input.form8.error::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.form8.error::-moz-placeholder {
  opacity: 1;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.form8.error:-moz-placeholder {
  opacity: 1;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.form8.error:-ms-input-placeholder {
  opacity: 1;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
@media (min-width: 1000px) {
  input.form8 {
    font-size: 15px;
    padding: 9px 16px 8px 16px;
    border-radius: 6px;
    max-height: 40px;
    min-height: 40px;
    border: 2px solid #d4d4d4;
    background: #ffffff;
  }
  input.form8::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #9a9a9a;
    text-align: left;
    line-height: normal;
  }
  input.form8::-moz-placeholder {
    opacity: 1;
    color: #9a9a9a;
    text-align: left;
    line-height: normal;
  }
  input.form8:-moz-placeholder {
    opacity: 1;
    color: #9a9a9a;
    text-align: left;
    line-height: normal;
  }
  input.form8:-ms-input-placeholder {
    opacity: 1;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #9a9a9a;
    text-align: left;
    line-height: normal;
  }
  input.form8.error {
    border-color: #f63f3f !important;
    color: #f63f3f !important;
  }
  input.form8.error::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #f63f3f;
    text-align: left;
    line-height: normal;
  }
  input.form8.error::-moz-placeholder {
    opacity: 1;
    color: #f63f3f;
    text-align: left;
    line-height: normal;
  }
  input.form8.error:-moz-placeholder {
    opacity: 1;
    color: #f63f3f;
    text-align: left;
    line-height: normal;
  }
  input.form8.error:-ms-input-placeholder {
    opacity: 1;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #f63f3f;
    text-align: left;
    line-height: normal;
  }
}
@media (min-width: 1000px) {
  input.formMobileLanding {
    background: white;
    border: 2px solid #c4c4c4;
    border-radius: 30px;
    line-height: 62px;
    height: 62px;
    width: 320px;
    font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif, sans-serif;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 0 20px 0 56px;
  }
  input.formMobileLanding::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #c4c4c4;
  }
  input.formMobileLanding::-moz-placeholder {
    opacity: 1;
    color: #c4c4c4;
  }
  input.formMobileLanding:-moz-placeholder {
    opacity: 1;
    color: #c4c4c4;
  }
  input.formMobileLanding:-ms-input-placeholder {
    opacity: 1;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #c4c4c4;
  }
  input.formMobileLanding.error {
    border: 2px solid #f63f3f !important;
    color: #f63f3f !important;
    background: #fff3e8;
  }
  input.formMobileLanding.error::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #f63f3f;
  }
  input.formMobileLanding.error::-moz-placeholder {
    opacity: 1;
    color: #f63f3f;
  }
  input.formMobileLanding.error:-moz-placeholder {
    opacity: 1;
    color: #f63f3f;
  }
  input.formMobileLanding.error:-ms-input-placeholder {
    opacity: 1;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #f63f3f;
  }
}
@media (min-width: 1000px) {
  input.formSub {
    width: 100%;
    font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif, sans-serif;
    color: #404040;
    line-height: 1;
    font-size: 12px;
    padding: 11px 30px 10px 30px;
    border-radius: 20px;
    max-height: 40px;
    border: 2px solid #fff;
    background: #fff;
    font-weight: bold;
    letter-spacing: 0.1em;
  }
  input.formSub::-webkit-input-placeholder {
    opacity: 1;
    color: #838383;
    font-weight: bold;
  }
  input.formSub::-moz-placeholder {
    opacity: 1;
    color: #838383;
    font-weight: bold;
  }
  input.formSub:-moz-placeholder {
    opacity: 1;
    color: #838383;
    font-weight: bold;
  }
  input.formSub:-ms-input-placeholder {
    opacity: 1;
    color: #838383;
    font-weight: bold;
  }
  input.formSub.error {
    border-color: #f63f3f !important;
    color: #f63f3f !important;
  }
  input.formSub.error::-webkit-input-placeholder {
    color: #f63f3f;
  }
  input.formSub.error::-moz-placeholder {
    color: #f63f3f;
  }
  input.formSub.error:-moz-placeholder {
    color: #f63f3f;
  }
  input.formSub.error:-ms-input-placeholder {
    color: #f63f3f;
  }
}
/*------New------*/
input[readonly] {
  cursor: no-drop;
}
input[readonly].ng-touched,
input[readonly].ng-untouched {
  cursor: pointer;
}
input.megaInput {
  width: 100%;
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif, sans-serif;
  color: #000;
  font-size: 13px;
  line-height: 1;
  padding: 8px 14px;
  border: 2px solid #d4d4d4;
  transition: all 0.3s ease-in-out;
  background: #fff;
  max-height: 36px;
  min-height: 36px;
  font-weight: 500;
  border-radius: 18px;
}
input.megaInput::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.megaInput::-moz-placeholder {
  opacity: 1;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.megaInput:-moz-placeholder {
  opacity: 1;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.megaInput:-ms-input-placeholder {
  opacity: 1;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.megaInput:active {
  border-color: #d4d4d4;
}
input.megaInput:focus {
  border-color: #d4d4d4;
}
input.megaInput.error {
  border-color: #f63f3f !important;
  color: #f63f3f !important;
}
input.megaInput.error::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.megaInput.error::-moz-placeholder {
  opacity: 1;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.megaInput.error:-moz-placeholder {
  opacity: 1;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.megaInput.error:-ms-input-placeholder {
  opacity: 1;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.megaInput.h50 {
  max-height: 45px;
  min-height: 45px;
  padding: 13px 14px 12px;
  border-radius: 25px;
}
input.megaInput.h40 {
  max-height: 40px;
  min-height: 40px;
  padding: 8px 20px 9px;
  border-radius: 25px;
  font-size: 15px;
}
input.megaInput.h36 {
  max-height: 36px;
  min-height: 36px;
  padding: 6px 18px 7px;
  border-radius: 25px;
  font-weight: bold;
  font-size: 10px;
}
@media (min-width: 1000px) {
  input.megaInput {
    font-size: 15px;
    max-height: 40px;
    min-height: 40px;
    border-radius: 30px;
    padding: 9px 24px 8px;
  }
  input.megaInput.h50 {
    max-height: 50px;
    min-height: 50px;
    padding: 14px 24px 13px;
  }
  input.megaInput.h36 {
    max-height: 36px;
    min-height: 36px;
    padding: 6px 18px 6px;
    font-size: 10px;
  }
}
input.mInput {
  width: 100%;
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif, sans-serif;
  color: #000;
  line-height: 1;
  border: 2px solid #d4d4d4;
  transition: all 0.3s ease-in-out;
  background: #fff;
  font-weight: 500;
  border-radius: 25px;
  padding: 0 20px;
}
input.mInput::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.mInput::-moz-placeholder {
  opacity: 1;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.mInput:-moz-placeholder {
  opacity: 1;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.mInput:-ms-input-placeholder {
  opacity: 1;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #707070;
  text-align: left;
  line-height: normal;
}
input.mInput:active {
  border-color: #d4d4d4;
}
input.mInput:focus {
  border-color: #d4d4d4;
}
input.mInput.error {
  border-color: #f63f3f !important;
  color: #f63f3f !important;
}
input.mInput.error::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.mInput.error::-moz-placeholder {
  opacity: 1;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.mInput.error:-moz-placeholder {
  opacity: 1;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.mInput.error:-ms-input-placeholder {
  opacity: 1;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #f63f3f;
  text-align: left;
  line-height: normal;
}
input.mInput.s-s_45 {
  max-height: 45px;
  min-height: 45px;
  line-height: 45px;
}
input.mInput.s-s_50 {
  max-height: 50px;
  min-height: 50px;
  line-height: 50px;
}
input.mInput.f-s_13 {
  font-size: 13px;
}
input.mInput.f-s_15 {
  font-size: 15px;
}
input.mInput.b_white {
  border-color: #fff;
  background-color: #fff;
  color: #000;
}
input.mInput.b_white::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #999999;
  text-align: left;
  line-height: normal;
}
input.mInput.b_white::-moz-placeholder {
  opacity: 1;
  color: #999999;
  text-align: left;
  line-height: normal;
}
input.mInput.b_white:-moz-placeholder {
  opacity: 1;
  color: #999999;
  text-align: left;
  line-height: normal;
}
input.mInput.b_white:-ms-input-placeholder {
  opacity: 1;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #999999;
  text-align: left;
  line-height: normal;
}
@media (min-width: 1000px) {
  input.mInput {
    padding: 0 25px;
  }
  input.mInput.s-m_45 {
    max-height: 45px;
    min-height: 45px;
    line-height: 45px;
  }
  input.mInput.s-m_50 {
    max-height: 50px;
    min-height: 50px;
    line-height: 50px;
  }
  input.mInput.f-m_13 {
    font-size: 13px;
  }
  input.mInput.f-m_15 {
    font-size: 15px;
  }
}
.for-btn-load {
  width: 100%;
  position: relative;
  display: inline-block;
}
.for-btn-load:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('./images/ma_load.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 80%;
  z-index: 5;
  opacity: 0;
  transform: scale(0);
  zoom: 1;
  transition: all 0.3s ease-in-out;
}
.for-btn-load .btn {
  z-index: 10;
}
.for-btn-load .mButton {
  z-index: 10;
}
.for-btn-load .bbt-elem-close-inn {
  z-index: 10;
}
.for-btn-load.loading:after {
  opacity: 1;
  transform: scale(1);
  z-index: 15;
}
.for-btn-load.loading .btn {
  opacity: 0;
}
.for-btn-load.loading .mButton {
  opacity: 0;
}
.for-btn-load.loading .bbt-elem-close-inn {
  opacity: 0;
}
.for-btn-load.loading .for-btn-load-text {
  z-index: 15;
  transform: scale(1);
  opacity: 1;
}
.mButton {
  display: block;
  width: 100%;
  position: relative;
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif;
  font-weight: 500;
  border: 0;
  line-height: 0;
  text-align: center;
  color: #fff;
  cursor: pointer;
  outline: 0;
  zoom: 1;
  transition: all 0.2s ease-in-out;
}
.mButton.mat-button {
  display: block;
}
.mButton.mw-s {
  display: block;
}
.mButton.mw-m {
  display: none;
}
.mButton .mButton-text {
  position: relative;
}
.mButton__line {
  color: #707070;
  background: transparent;
  padding: 0 10px;
  border: 2px solid #707070;
}
.mButton__line.mat-button {
  border: 2px solid #707070;
}
.mButton__line.c_red {
  color: #f63f3f;
  background: transparent;
  border-color: #f63f3f;
}
.mButton__line.c_red:hover {
  color: #fff;
  background: #f63f3f;
  border-color: #f63f3f;
}
.mButton__line.c_red:active {
  color: #fff;
  background: #f63f3f;
  border-color: #f63f3f;
}
.mButton__line.c_sky {
  color: #96cee0;
  background: transparent;
  border-color: #96cee0;
}
.mButton__line.c_sky:hover {
  color: #96cee0;
  background: transparent;
  border-color: #96cee0;
}
.mButton__line.c_sky:active {
  color: #96cee0;
  background: transparent;
  border-color: #96cee0;
}
.mButton__line.c_blue {
  color: #68abc1;
  background: transparent;
  border-color: #68abc1;
}
.mButton__line.c_blue:hover {
  color: #68abc1;
  background: transparent;
  border-color: #68abc1;
}
.mButton__line.c_blue:active {
  color: #68abc1;
  background: transparent;
  border-color: #68abc1;
}
.mButton__line.c_yellow {
  color: #DAB758;
  background: transparent;
  border-color: #DAB758;
}
.mButton__line.c_yellow:hover {
  color: #DAB758;
  background: transparent;
  border-color: #DAB758;
}
.mButton__line.c_yellow:active {
  color: #DAB758;
  background: transparent;
  border-color: #DAB758;
}
.mButton__line.c_gray {
  color: #C4C4C4;
  background: transparent;
  border-color: #C4C4C4;
}
.mButton__line.c_gray:hover {
  color: #C4C4C4;
  background: transparent;
  border-color: #C4C4C4;
}
.mButton__line.c_gray:active {
  color: #C4C4C4;
  background: transparent;
  border-color: #C4C4C4;
}
.mButton__line.c_white {
  color: #fff;
  background: transparent;
  border-color: #fff;
}
.mButton__line.c_white:hover {
  color: #fff;
  background: transparent;
  border-color: #fff;
}
.mButton__line.c_white:active {
  color: #fff;
  background: transparent;
  border-color: #fff;
}
.mButton__line:disabled,
.mButton__line.disabled,
.mButton__line.c_disabled {
  color: #E7E7E7;
  background: transparent;
  border-color: #E7E7E7;
  cursor: default;
}
.mButton__line:disabled:hover,
.mButton__line.disabled:hover,
.mButton__line.c_disabled:hover {
  color: #E7E7E7;
  background: transparent;
  border-color: #E7E7E7;
}
.mButton__line:disabled:active,
.mButton__line.disabled:active,
.mButton__line.c_disabled:active {
  color: #E7E7E7;
  background: transparent;
  border-color: #E7E7E7;
}
.mButton__line.s-s_30 {
  border-radius: 20px;
  height: 30px;
  border-width: 1px!important;
}
.mButton__line.s-s_30 .mButton-text {
  line-height: 28px;
}
.mButton__line.s-s_36 {
  border-radius: 20px;
  height: 36px;
}
.mButton__line.s-s_36 .mButton-text {
  line-height: 32px;
}
.mButton__line.s-s_40 {
  border-radius: 20px;
  height: 40px;
}
.mButton__line.s-s_40 .mButton-text {
  line-height: 36px;
}
.mButton__line.s-s_45 {
  border-radius: 25px;
  height: 45px;
}
.mButton__line.s-s_45 .mButton-text {
  line-height: 41px;
}
.mButton__line.s-s_50 {
  border-radius: 25px;
  height: 50px;
}
.mButton__line.s-s_50 .mButton-text {
  line-height: 46px;
}
.mButton__line .mButton-text {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
}
.mButton__line .f-s_10 {
  font-size: 10px;
}
.mButton__line .f-s_11 {
  font-size: 11px;
}
.mButton__line .f-s_12 {
  font-size: 12px;
}
.mButton__line .f-s_14 {
  font-size: 14px;
}
.mButton__full {
  color: #fff;
  background: #707070;
  padding: 0 10px;
  border: 2px solid #707070;
}
.mButton__full.mat-button {
  border: 2px solid #707070;
}
.mButton__full.c_red {
  color: #fff;
  background: #f63f3f;
  border-color: #f63f3f;
}
.mButton__full.c_red:hover {
  color: #fff;
  background: #f63f3f;
  border-color: #f63f3f;
}
.mButton__full.c_red:active {
  color: #fff;
  background: #f63f3f;
  border-color: #f63f3f;
}
.mButton__full.c_sky {
  color: #fff;
  background: #96cee0;
  border-color: #96cee0;
}
.mButton__full.c_sky:hover {
  color: #fff;
  background: #96cee0;
  border-color: #96cee0;
}
.mButton__full.c_sky:active {
  color: #fff;
  background: #96cee0;
  border-color: #96cee0;
}
.mButton__full.c_blue {
  color: #fff;
  background: #68abc1;
  border-color: #68abc1;
}
.mButton__full.c_blue:hover {
  color: #fff;
  background: #68abc1;
  border-color: #68abc1;
}
.mButton__full.c_blue:active {
  color: #fff;
  background: #68abc1;
  border-color: #68abc1;
}
.mButton__full.c_yellow {
  color: #fff;
  background: #DAB758;
  border-color: #DAB758;
}
.mButton__full.c_yellow:hover {
  color: #fff;
  background: #DAB758;
  border-color: #DAB758;
}
.mButton__full.c_yellow:active {
  color: #fff;
  background: #DAB758;
  border-color: #DAB758;
}
.mButton__full.c_gray {
  color: #fff;
  background: #C4C4C4;
  border-color: #C4C4C4;
}
.mButton__full.c_gray:hover {
  color: #fff;
  background: #C4C4C4;
  border-color: #C4C4C4;
}
.mButton__full.c_gray:active {
  color: #fff;
  background: #C4C4C4;
  border-color: #C4C4C4;
}
.mButton__full.c_white {
  color: #000;
  background: #fff;
  border-color: #fff;
}
.mButton__full.c_white:hover {
  color: #000;
  background: #fff;
  border-color: #fff;
}
.mButton__full.c_white:active {
  color: #000;
  background: #fff;
  border-color: #fff;
}
.mButton__full:disabled,
.mButton__full.disabled,
.mButton__full.c_disabled {
  color: #CCCCCC;
  background: #E7E7E7;
  border-color: #E7E7E7;
  cursor: default;
}
.mButton__full:disabled:hover,
.mButton__full.disabled:hover,
.mButton__full.c_disabled:hover {
  color: #CCCCCC;
  background: #E7E7E7;
  border-color: #E7E7E7;
}
.mButton__full:disabled:active,
.mButton__full.disabled:active,
.mButton__full.c_disabled:active {
  color: #CCCCCC;
  background: #E7E7E7;
  border-color: #E7E7E7;
}
.mButton__full.s-s_30 {
  border-radius: 20px;
  height: 30px;
  border-width: 1px!important;
}
.mButton__full.s-s_30 .mButton-text {
  line-height: 28px;
}
.mButton__full.s-s_40 {
  border-radius: 20px;
  height: 40px;
}
.mButton__full.s-s_40 .mButton-text {
  line-height: 36px;
}
.mButton__full.s-s_36 {
  border-radius: 20px;
  height: 36px;
}
.mButton__full.s-s_36 .mButton-text {
  line-height: 32px;
}
.mButton__full.s-s_45 {
  border-radius: 25px;
  height: 45px;
}
.mButton__full.s-s_45 .mButton-text {
  line-height: 41px;
}
.mButton__full.s-s_50 {
  border-radius: 25px;
  height: 50px;
}
.mButton__full.s-s_50 .mButton-text {
  line-height: 46px;
}
.mButton__full .mButton-text {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
}
.mButton__full .f-s_10 {
  font-size: 10px;
}
.mButton__full .f-s_11 {
  font-size: 11px;
}
.mButton__full .f-s_12 {
  font-size: 12px;
}
.mButton__full .f-s_14 {
  font-size: 14px;
}
@media (min-width: 1000px) {
  .mButton.mw-s {
    display: none;
  }
  .mButton.mw-m {
    display: block;
  }
  .mButton__line.s-m_30 {
    border-radius: 20px;
    height: 30px;
    border-width: 1px!important;
  }
  .mButton__line.s-m_30 .mButton-text {
    line-height: 28px;
  }
  .mButton__line.s-m_35 {
    border-radius: 20px;
    height: 35px;
  }
  .mButton__line.s-m_35 .mButton-text {
    line-height: 31px;
  }
  .mButton__line.s-m_40 {
    border-radius: 20px;
    height: 40px;
  }
  .mButton__line.s-m_40 .mButton-text {
    line-height: 36px;
  }
  .mButton__line.s-m_45 {
    border-radius: 25px;
    height: 45px;
  }
  .mButton__line.s-m_45 .mButton-text {
    line-height: 41px;
  }
  .mButton__line.s-m_50 {
    border-radius: 25px;
    height: 50px;
  }
  .mButton__line.s-m_50 .mButton-text {
    line-height: 46px;
  }
  .mButton__line .f-m_10 {
    font-size: 10px;
  }
  .mButton__line .f-m_11 {
    font-size: 11px;
  }
  .mButton__line .f-m_12 {
    font-size: 12px;
  }
  .mButton__line .f-m_14 {
    font-size: 14px;
  }
  .mButton__full.s-m_30 {
    border-radius: 20px;
    height: 30px;
    border-width: 1px!important;
  }
  .mButton__full.s-m_30 .mButton-text {
    line-height: 28px;
  }
  .mButton__full.s-m_35 {
    border-radius: 20px;
    height: 35px;
  }
  .mButton__full.s-m_35 .mButton-text {
    line-height: 31px;
  }
  .mButton__full.s-m_40 {
    border-radius: 20px;
    height: 40px;
  }
  .mButton__full.s-m_40 .mButton-text {
    line-height: 36px;
  }
  .mButton__full.s-m_45 {
    border-radius: 25px;
    height: 45px;
  }
  .mButton__full.s-m_45 .mButton-text {
    line-height: 41px;
  }
  .mButton__full.s-m_50 {
    border-radius: 25px;
    height: 50px;
  }
  .mButton__full.s-m_50 .mButton-text {
    line-height: 46px;
  }
  .mButton__full .f-m_10 {
    font-size: 10px;
  }
  .mButton__full .f-m_11 {
    font-size: 11px;
  }
  .mButton__full .f-m_12 {
    font-size: 12px;
  }
  .mButton__full .f-m_14 {
    font-size: 14px;
  }
}
.page-loader {
  display: block;
  text-align: center;
  color: #f63f3f;
  padding: 0;
}
.page-loader img {
  display: block;
  margin: 0 auto;
}
.newModal {
  text-align: center;
  color: #000;
}
.newModal-h {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 14px;
}
.newModal-text {
  font-size: 13px;
  line-height: 1.5;
  margin-bottom: 9px;
}
.newModal-text p {
  margin-bottom: 10px;
}
.newModal-text p:last-child {
  margin-bottom: 0;
}
.newModal-close {
  display: block;
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  top: 16px;
  right: 16px;
}
.newModal-close:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('./images/i/i_newModal_close.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.newModal-close.Bcolor:before {
  background-image: url('./images/i/i_newModal_close_black.svg');
}
@media (min-width: 1000px) {
  .newModal-h {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .newModal-text {
    font-size: 15px;
    margin-bottom: 22px;
  }
  .newModal-close {
    width: 17px;
    height: 17px;
  }
  .newModal-close:before {
    transition: opacity 0.2s ease-in-out;
  }
  .newModal-close:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('./images/i/i_newModal_close_black.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  .newModal-close.Bcolor:before {
    background-image: url('./images/i/i_newModal_close.svg');
  }
  .newModal-close:hover:before {
    opacity: 0;
  }
  .newModal-close:hover:after {
    opacity: 1;
  }
}
.arrivalModal .mat-dialog-container {
  padding: 0!important;
}
.haveEmail-link {
  text-align: center;
}
.haveEmail-link a {
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif;
  color: #f63f3f;
  font-size: 13px;
  line-height: 1;
  font-weight: bold;
  background: none!important;
  padding-bottom: 0 !important;
  border-bottom: 1px #f63f3f dashed;
}
@media (min-width: 1000px) {
  html#desktop .haveEmail-link {
    margin: 12px 0 0 20px;
    text-align: left;
  }
  html#desktop .haveEmail-link a {
    font-size: 15px;
    transition: border-color 0.2s ease-in-out;
    border-width: 1px;
  }
  html#desktop .haveEmail-link a:hover {
    border-color: transparent;
  }
}
.tov-section {
  padding: 10px 0 15px;
  overflow: hidden;
}
.tov-section .thisCb {
  margin-top: 10px;
}
.tov-section .thisCb .mat-checkbox-inner-container {
  width: 16px;
  height: 16px;
}
.tov-section .thisCb .mat-checkbox-label {
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
.tov-section .thisCb .mat-checkbox-checked .mat-checkbox-label {
  color: #f63f3f;
}
.tov-section.gray__bg {
  padding: 20px 0 15px;
}
.tov-section.dgray__bg {
  padding: 20px 0 15px;
}
.tov-section__pb80 {
  padding-bottom: 40px;
}
.tov-section-h {
  margin-bottom: 24px;
}
.tov-section-h h1,
.tov-section-h h2 {
  display: inline;
  margin-bottom: 10px;
}
.tov-section-h .thisH {
  display: inline;
  margin-bottom: 10px;
  font-size: 19px;
  line-height: 1.36;
  color: #000;
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif;
  font-weight: 500;
}
.tov-section-h a {
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif;
  font-weight: normal;
  font-size: 13px;
  color: #f63f3f;
  margin-left: 10px;
  float: right;
  margin-top: 4px;
}
.tov-section-h a span.und {
  background: -moz-linear-gradient(to right, #f63f3f, #f63f3f 100%);
  background: -ms-linear-gradient(to right, #f63f3f, #f63f3f 100%);
  background: -o-linear-gradient(to right, #f63f3f, #f63f3f 100%);
  background: -webkit-gradient(linear, 0 0, 100% 0, from(#f63f3f), to(#f63f3f));
  background: -webkit-linear-gradient(to right, #f63f3f, #f63f3f 100%);
  background: linear-gradient(to right, #f63f3f, #f63f3f 100%);
  background-position: 0 90%;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  padding-bottom: 1px;
}
.tov-section-h .h2-btn {
  width: 180px;
  margin-top: 5px;
}
.tov-section-h .h2-btn .btn.btn_nsize {
  padding: 6px 6px 7px;
}
.tov-section-table-over .c-card-product:first-child .product {
  background: unset;
}
.tov-section-table-over app-items-card:first-child .tovar {
  background: unset;
}
.tov-section-table-over .c-card-product:nth-last-child(2) {
  position: relative;
  box-shadow: 0px 6px 6px 0px #E8E8E8;
}
.tov-section-table-over .c-card-product:nth-last-child(2):before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  border-bottom: 2px dotted #B1B1B1;
  z-index: 2;
  transition: all 0.15s ease-in-out;
}
@media (min-width: 1000px) {
  .tov-section-table-over .c-card-product:nth-last-child(2) {
    box-shadow: none;
  }
  .tov-section-table-over .c-card-product:nth-last-child(2):before {
    border-bottom: none;
  }
}
.tov-section-table-over app-items-card {
  display: block;
}
.tov-section-table-over app-items-card:nth-last-child(2) {
  position: relative;
  box-shadow: 0px 6px 6px 0px #E8E8E8;
}
.tov-section-table-over app-items-card:nth-last-child(2) .tovar-footer {
  height: 36px;
}
.tov-section-table-over app-items-card:nth-last-child(2):before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  border-bottom: 2px dotted #B1B1B1;
  z-index: 2;
  transition: all 0.15s ease-in-out;
}
@media (min-width: 1000px) {
  .tov-section-table-over app-items-card:nth-last-child(2) {
    box-shadow: none;
  }
  .tov-section-table-over app-items-card:nth-last-child(2):before {
    border-bottom: none;
  }
}
.tov-section-table-over-empty {
  color: #000;
  max-width: 300px;
  margin: 20px auto;
  line-height: 1.36;
  font-size: 15px;
}
.tov-section-table-more {
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif;
  display: table;
  width: 300px;
  margin: 0 auto;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 500;
}
.tov-section-table-more-kol {
  float: left;
  max-width: 50%;
  margin: 11px 0 0;
}
.tov-section-table-more-load {
  font-family: "Montserrat", "Helvetica", "Arial Black", "Arial", sans-serif;
  position: relative;
  float: right;
  max-width: 50%;
  text-align: right;
  margin: 15px 0 0;
}
.tov-section-table-more-load:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('./images/ma_load.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  z-index: 5;
  opacity: 0;
  transform: scale(0);
  zoom: 1;
  transition: all 0.3s ease-in-out;
}
.tov-section-table-more-load.loading:after {
  opacity: 1;
  transform: scale(1);
  z-index: 15;
}
.tov-section-table-more-load.loading a {
  opacity: 0;
}
.tov-section-table-more a {
  color: #f63f3f;
  background: none!important;
  padding-bottom: 0 !important;
  border-bottom: 1px #f63f3f dashed;
}
.tov-section-table-more a.lgr {
  color: #ff6868;
  background: none!important;
  padding-bottom: 0 !important;
  border-bottom: 1px #ff6868 dashed;
}
@media (min-width: 1000px) {
  html#desktop .tov-section {
    padding: 20px 0 30px;
    overflow: visible;
  }
  html#desktop .tov-section .thisCb {
    float: right;
    margin-top: 10px;
  }
  html#desktop .tov-section .thisCb .mat-checkbox-inner-container {
    width: 16px;
    height: 16px;
  }
  html#desktop .tov-section .thisCb .mat-checkbox-label {
    font-size: 13px;
    line-height: 24px;
  }
  html#desktop .tov-section.gray__bg {
    padding: 36px 0 30px;
  }
  html#desktop .tov-section.dgray__bg {
    padding: 36px 0 30px;
  }
  html#desktop .tov-section__pb80 {
    padding-bottom: 80px;
  }
  html#desktop .tov-section-h {
    width: 100%;
    display: table;
    margin-bottom: 22px;
  }
  html#desktop .tov-section-h h1,
  html#desktop .tov-section-h h2 {
    max-width: 920px;
    margin-bottom: 0;
    float: left;
  }
  html#desktop .tov-section-h .thisH {
    max-width: 920px;
    float: left;
    font-size: 32px;
    line-height: 1.23;
    margin-bottom: 0;
  }
  html#desktop .tov-section-h a {
    font-size: 18px;
    margin-left: 14px;
    float: none;
    margin-top: 0;
  }
  html#desktop .tov-section-h a span.und {
    transition: all 0.2s ease-in-out;
  }
  html#desktop .tov-section-h a:hover span.und {
    background-position: 0 120%;
  }
  html#desktop .tov-section-h a.title {
    font-size: 32px;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
  html#desktop .tov-section-h a.title:hover {
    background-position: 0 120%;
  }
  html#desktop .tov-section-h .h2-btn {
    width: auto;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 23px;
  }
  html#desktop .tov-section-h .h2-btn .btn.btn_nsize {
    padding: 8px 22px 9px;
    margin: 4px 0 -5px;
  }
  html#desktop .tov-section-table-over {
    display: flex;
    flex-flow: row wrap;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: -11px;
    margin-right: -11px;
  }
  html#desktop .tov-section-table-over app-items-card:first-child {
    border: 0;
  }
  html#desktop .tov-section-table-over-empty {
    margin: 20px auto;
    max-width: 100%;
    line-height: 1.23;
    font-size: 20px;
    text-align: center;
  }
  html#desktop .tov-section-table .tovar {
    margin-left: 11px;
    margin-right: 11px;
    margin-bottom: 20px;
  }
  html#desktop .tov-section-table-more {
    width: 100%;
    display: block;
    font-size: 15px;
    margin: 0 11px;
    line-height: 1;
  }
  html#desktop .tov-section-table-more-kol {
    float: right;
    margin: 20px 0 20px;
  }
  html#desktop .tov-section-table-more-load {
    float: none;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    margin: 20px 0 20px;
  }
  html#desktop .tov-section-table-more a {
    color: #f63f3f;
    background: none!important;
    padding-bottom: 0 !important;
    border-bottom: 1px #f63f3f dashed;
    transition: all 0.2s ease-in-out;
  }
  html#desktop .tov-section-table-more a:hover {
    border-bottom: 1px transparent dashed;
  }
  html#desktop .tov-section-table-more a.lgr {
    color: #f63f3f;
    background: none!important;
    padding-bottom: 0 !important;
    border-bottom: 1px #f63f3f dashed;
    transition: all 0.2s ease-in-out;
  }
  html#desktop .tov-section-table-more a.lgr:hover {
    border-bottom: 1px transparent dashed;
  }
}
lightbox {
  box-shadow: none!important;
}
lightbox gallery {
  background-color: transparent!important;
}
lightbox .g-btn-close {
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('./images/i/i_appClose2-m.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
lightbox .g-btn-close svg {
  display: none;
}
lightbox .g-btn-close:hover {
  background-color: #000;
}
lightbox .g-btn-close:active {
  background-color: #000;
}
lightbox .g-nav-prev {
  display: block;
  position: absolute;
  width: 30px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  top: 50%;
  margin-top: -25px;
  z-index: 20;
  font-size: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  transform: translate(0, 0) !important;
  left: -1px;
  border-radius: 0 25px 25px 0;
}
lightbox .g-nav-prev:before {
  content: '';
  position: absolute;
  width: 9px;
  height: 15px;
  top: 50%;
  margin-top: -7px;
  background-image: url('./images/mobile/arrow.png');
  background-repeat: no-repeat;
  background-size: cover;
  left: 8px;
  background-position: left 0;
}
lightbox .g-nav-prev:hover {
  background-color: #000;
}
lightbox .g-nav-prev:active {
  background-color: #000;
}
lightbox .g-nav-prev svg {
  display: none;
}
lightbox .g-nav-next {
  display: block;
  position: absolute;
  width: 30px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  top: 50%;
  margin-top: -25px;
  z-index: 20;
  font-size: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  transform: translate(0, 0) !important;
  right: -1px;
  border-radius: 25px 0 0 25px;
}
lightbox .g-nav-next:before {
  content: '';
  position: absolute;
  width: 9px;
  height: 15px;
  top: 50%;
  margin-top: -7px;
  background-image: url('./images/mobile/arrow.png');
  background-repeat: no-repeat;
  background-size: cover;
  right: 8px;
  background-position: right 0;
}
lightbox .g-nav-next:hover {
  background-color: #000;
}
lightbox .g-nav-next:active {
  background-color: #000;
}
lightbox .g-nav-next svg {
  display: none;
}
@media (min-width: 1000px) {
  html#desktop lightbox {
    position: static!important;
  }
  html#desktop lightbox gallery {
    position: static!important;
  }
  html#desktop lightbox gallery-core,
  html#desktop lightbox .g-box,
  html#desktop lightbox gallery-slider {
    position: static!important;
    background-color: var(--white) !important;
  }
}
