.newModal {
  text-align: center;
  color:@black;
  &-h {
    font-weight: bold;
    font-size: 15px;
    line-height: 1.2;
    margin-bottom:14px;
  }
  &-text {
    font-size: 13px;
    line-height: 1.5;
    margin-bottom:9px;
    p {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom:0;
      }
    }
  }
  &-close {
    display: block;
    width:15px;
    height:15px;
    cursor: pointer;
    position: absolute;
    z-index: 5;
    top: 16px;
    right: 16px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url('../images/i/i_newModal_close.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.Bcolor {
      &:before {
        background-image: url('../images/i/i_newModal_close_black.svg');
      }
    }
  }

  @media (min-width: @mw-m-up) {
    &-h {
      font-size: 22px;
      margin-bottom:20px;
    }
    &-text {
      font-size: 15px;
      margin-bottom:22px;
    }
    &-close {
      width:17px;
      height:17px;

      &:before {
        transition: opacity 0.2s ease-in-out;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url('../images/i/i_newModal_close_black.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &.Bcolor {
        &:before {
          background-image: url('../images/i/i_newModal_close.svg');
        }
      }

      &:hover {
        &:before {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }
}



.arrivalModal {
  .mat-dialog-container {
    padding: 0!important;
  }
}
