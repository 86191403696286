.skeleton {
    background-color: var(--grey-light);
    background: linear-gradient(100deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%)
        var(--grey-light);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1.4s skeleton ease-in-out infinite;
    color: transparent !important;

    * > {
        color: transparent !important;
        background-color: transparent !important;
        opacity: 0;
    }

    &--text {
        border-radius: 4px;
    }
}

.skeleton--box {
    box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
    border-radius: 4px;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        display: block;
        position: absolute;
        left: -150px;
        top: 0;
        height: 100%;
        width: 150px;
        background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
        animation: load 1s cubic-bezier(0.4, 0, 0.2, 1);
    }
}
@keyframes skeleton {
    to {
        background-position-x: -20%;
    }
}
