.setUnderline () {
    text-decoration: underline;
    text-underline-offset: 1px;
    &:hover {
        text-decoration: none;
    }
}
.setDashed () {
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 1px;

    &:hover {
        text-decoration: none;
    }
}
.removeUnderline() {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
