html#desktop{
  @media (min-width: @mw-m-up) {
    //----start----//
    body {
        //min-width:@mw-m;
    }
    #wrapper {
      height: auto;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
      -moz-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      position: relative;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
    }
    #bottom {
      height: auto;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -moz-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      position: relative;
    }

    #mm {
      display: none;
    }
    .mw-s {
      display: none;
    }
    .mw-m {
      display: block;
    }
    .br-s {
      display: inline-block;

      vertical-align: top;
    }
    .br-m {
      display: block;
    }
    .htext-s {
      display: none;
    }
    .htext-m {
      display: inline;
    }

    .wrap{
      width:@mw-m;
      padding-right: 10px;
      padding-left: 10px;
      &-s {
        width:100%;
        padding-right:0;
        padding-left:0;
        margin:0;
      }
      &-m {
        width:@mw-m;
        padding-right: 10px;
        padding-left: 10px;
        margin:0 auto;
      }
    }

    .gray__bg {
      background:#e7e7e7!important;
    }
    .dgray__bg {
      background: #dcdcdc!important;
    }
    .non__bg {
      background:transparent!important;
    }

    //-----end-----//
  }
}
