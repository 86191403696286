.tov-section {
  padding:10px 0 15px;
  overflow: hidden;
  .thisCb {
    margin-top:10px;
    .mat-checkbox-inner-container {
      width:16px;
      height:16px;
    }
    .mat-checkbox-label {
      font-family:@mont;
      font-weight:500;
      font-size: 13px;
      line-height: 16px;
    }
    .mat-checkbox-checked {
      .mat-checkbox-label {
        color:@red;
      }
    }
  }
  &.gray__bg {
    padding:20px 0 15px;
  }
  &.dgray__bg {
    padding:20px 0 15px;
  }
  &__pb80 {
    padding-bottom:40px;
  }
  &-h {
    margin-bottom: 24px;
    h1, h2 {
      display: inline;
      margin-bottom:10px;
    }
    .thisH {
      display: inline;
      margin-bottom:10px;
      font-size:19px;
      line-height: 1.36;
      color:@black;
      font-family:@mont;
      font-weight: 500;
    }
    a {
      font-family:@mont;
      font-weight: normal;
      font-size:13px;
      color:@red;
      margin-left: 10px;
      float:right;
      margin-top:4px;
      span.und {
        .underline (@red, 90%, 1px, 1px);
      }
    }
    .h2-btn {
      width: 180px;
      margin-top: 5px;
      .btn.btn_nsize {
        padding: 6px 6px 7px;
      }
    }
  }
  &-table{
    &-over {
        .c-card-product:first-child {
            .product {
                background: unset;
            }
        }
        app-items-card:first-child {
            .tovar {
                background: unset;
            }

        }
        .c-card-product {
            &:nth-last-child(2) {
                position: relative;
                box-shadow: 0px 6px 6px 0px #E8E8E8;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    right: 0;
                    border-bottom: 2px dotted #B1B1B1;
                    z-index: 2;
                    transition: all 0.15s ease-in-out;
                }
                @media (min-width: @mw-m-up) {
                    box-shadow: none;
                    &:before {
                        border-bottom: none;
                    }
                }
            }
        }
      app-items-card {
        display: block;
          &:nth-last-child(2) {
              position: relative;
              box-shadow: 0px 6px 6px 0px #E8E8E8;
              .tovar-footer {
                  height: 36px;
              }
              &:before {
                  content: '';
                  position: absolute;
                  bottom: -2px;
                  left: 0;
                  right: 0;
                  border-bottom: 2px dotted #B1B1B1;
                  z-index: 2;
                  transition: all 0.15s ease-in-out;
              }
              @media (min-width: @mw-m-up) {
                  box-shadow: none;
                  &:before {
                      border-bottom: none;
                  }
              }
          }
      }

      &-empty {
        color:@black;
        max-width: @mw-s - 20;
        margin: 20px auto;
        line-height: 1.36;
        font-size: 15px;
      }
    }
    &-more { // +
      font-family: @mont;
      display: table;
      width:@mw-s - 20;
      margin:0 auto;
      font-size:13px;
      line-height: 1.5;
      font-weight: 500;
      &-kol {
        float:left;
        max-width:50%;
        margin:11px 0 0;
      }
      &-load {
        font-family: @mont;
        position: relative;
        float: right;
        max-width:50%;
        text-align: right;
        margin:15px 0 0;
        &:after {
          content:'';
          display:block;
          position:absolute;
          width:100%;
          height:100%;
          left:0;
          right:0;
          top:0;
          bottom:0;
          background-image: @ma-load-gif;
          background-repeat: no-repeat;
          background-position:center;
          background-size: auto 100%;
          z-index:5;
          opacity: 0;
          transform: scale(0);
          zoom: 1;
          transition: all 0.3s ease-in-out;
        }
        &.loading {
          &:after {
            opacity: 1;
            transform: scale(1);
            z-index:15;
          }
          a {
            opacity: 0;
          }
        }
      }
      a {
        color:@red;
        .underline_dashed (@red, 90%, 1px, 0);
      }
      a.lgr {
        color:@red2;
        .underline_dashed (@red2, 90%, 1px, 0);
      }
    }
  }

  @media (min-width: @mw-m-up) {
    html#desktop & {
      padding:20px 0 30px;
      overflow: visible;
      .thisCb {
        float:right;
        margin-top:10px;
        .mat-checkbox-inner-container {
          width:16px;
          height:16px;
        }
        .mat-checkbox-label {
          font-size:13px;
          line-height: 24px;
        }
      }
      &.gray__bg {
        padding:36px 0 30px;
      }
      &.dgray__bg {
        padding:36px 0 30px;
      }
      &__pb80 {
        padding-bottom:80px;
      }
      &-h {
        width:100%;
        display: table;
        margin-bottom:22px;
        h1, h2 {
          max-width: 920px;
          margin-bottom:0;
          float:left;
        }
        .thisH {
          max-width: 920px;
          float:left;
          font-size:32px;
          line-height: 1.23;
          margin-bottom:0;
        }
        a {
          font-size:18px;
          margin-left: 14px;
          float:none;
          margin-top:0;
          span.und {
            transition: all 0.2s ease-in-out;
          }
          &:hover {
            span.und {
              background-position: 0 120%;
            }
          }
          &.title {
            font-size:32px;
            margin:0;
            transition: all 0.2s ease-in-out;
            &:hover {
              background-position: 0 120%;
            }
          }
        }
        .h2-btn {
          width:auto;
          display: inline-block;

          vertical-align: top;
          margin:0 0 0 23px;
          .btn.btn_nsize {
            padding: 8px 22px 9px;
            margin: 4px 0 -5px;
          }
        }
      }
      &-table{
        &-over {
          app-items-card {
            &:first-child {
              border: 0;
            }
          }
          display: flex;
          flex-flow: row wrap;
          flex-wrap: wrap;
          flex-direction: row;
          margin-left: -11px;
          margin-right: -11px;
          &-empty {
            margin: 20px auto;
            max-width: 100%;
            line-height: 1.23;
            font-size: 20px;
            text-align: center;
          }
        }
        .tovar {
          margin-left:11px;
          margin-right:11px;
          margin-bottom:20px;
        }
        &-more {
          width:100%;
          display: block;
          font-size:15px;
          margin:0 11px;
          line-height: 1;
          &-kol {
            float:right;
            margin:20px 0 20px;
          }
          &-load {
            float: none;
            display: inline-block;

            vertical-align: top;
            text-align: left;
            margin:20px 0 20px;
          }
          a {
            color:@red;
            .underline_dashed (@red, 90%, 1px, 0);
            transition: all 0.2s ease-in-out;
            &:hover {
              border-bottom: 1px transparent dashed;
            }
          }
          a.lgr {
            color:@red;
            .underline_dashed (@red, 90%, 1px, 0);
            transition: all 0.2s ease-in-out;
            &:hover {
              border-bottom: 1px transparent dashed;
            }
          }
        }
      }
    }
  }
}
