@import "../variables";

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: @offset-wrapper-s;
}

h1 {
    font-size: 15px;
}

h2 {
    font-size: 15px;
}

h3, h4, h5, h6 {
    font-size: 13px;
}


@media (min-width: @size-bp-s) {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: @offset-wrapper-m;
    }

    h1 {
        font-size: 30px;
        margin-bottom: @offset-wrapper-l;
    }

    h2, h3, h4, h5, h6 {
        font-size: 15px;
    }


}
