/* montserrat-300 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.eot'); /* IE9 Compat Modes */
    src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.woff') format('woff'), /* Modern Browsers */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.eot'); /* IE9 Compat Modes */
    src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.woff') format('woff'), /* Modern Browsers */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.eot'); /* IE9 Compat Modes */
    src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.woff') format('woff'), /* Modern Browsers */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.eot'); /* IE9 Compat Modes */
    src: url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.woff') format('woff'), /* Modern Browsers */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://sc.megapteka.ru/fonts/montserrat/google-v2/montserrat-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
