@white:      #fff;
@black:      #000;
@color:      #404040;
@orange:     #f3990a;
@purp:       #8058b6;
@red:        #f63f3f; // По умолчанию
@red2:       #ff6868;
@red3:       #FF8282;
@red4:       #E42D2D;
@green:      #00732d;
@gray:       #999999;
@gray2:      #707070;
@gray3:      #E6E6E6;
@gray4:      #D2D2D2;
@gray5:      #E5E5E5;
@gray6:      #808080;
@gray7:      #BBBBBB;
@gray8:      #D4D4D4;
@gray9:      #838383;
@gray10:     #C8C8C8;
@blue:       #68abc1;
@blue2:      #D5E7EE;
@sky:        #96cee0;
@salat:      #6fa300;
@salat2:     #659500;
@salat3:     #5a8400;


@mw-s:        320px;
@mw-m:        1260px;
@mw-l:        960px;
@mw-m-up:     1000px;
@mw-m-down:   999px;
@mw-l-up:     1360px;
@mw-l-down:   1359px;


@ma-load-gif:               url('../images/ma_load.gif');

.underline (@und: #fff, @start: 90%, @size: 1px, @size2: 1px) {
	background: -moz-linear-gradient(to right, @und, @und 100%);
	background: -ms-linear-gradient(to right, @und, @und 100%);
	background: -o-linear-gradient(to right, @und, @und 100%);
	background: -webkit-gradient(linear, 0 0, 100% 0, from(@und), to(@und));
	background: -webkit-linear-gradient(to right, @und, @und 100%);
	background: linear-gradient(to right, @und, @und 100%);
	background-position: 0 @start;
	background-size: 10px @size;
	background-repeat: repeat-x;
    padding-bottom:@size2;
}
.underline_dashed (@und: #fff, @start: 90%, @size: 1px, @size2: 0) {
    //background: -moz-linear-gradient(to left, @und 50%, rgba(0,0,0,0) 50%);
    //background: -ms-linear-gradient(to left, @und 50%, rgba(0,0,0,0) 50%);
    //background: -o-linear-gradient(to left, @und 50%, rgba(0,0,0,0) 50%);
    //background: linear-gradient(to left, @und 50%, rgba(0,0,0,0) 0%);
    //background: -webkit-gradient(linear, 50% 0, 50% 0, from(@und), to(rgba(0,0,0,0)) 0%);
    //background: -webkit-linear-gradient(to left, @und 50%, rgba(0,0,0,0) 0%);
    //background-position: 0 @start;
    //background-size: @size2 @size;
    //background-repeat: repeat-x;
    background:none!important;
    padding-bottom:@size2!important;
    border-bottom: @size @und dashed;
}

.placeholder(@pcolor: defualut) {
    &::-webkit-input-placeholder {opacity:1; -webkit-transition: all 0.2s ease-in-out; transition: all 0.2s ease-in-out;color:@pcolor;text-align: center;line-height: normal;}
    &::-moz-placeholder          {opacity:1;color:@pcolor;text-align: center;line-height: normal;}
    &:-moz-placeholder           {opacity:1;color:@pcolor;text-align: center;line-height: normal;}
    &:-ms-input-placeholder      {opacity:1; -o-transition: all 0.2s ease-in-out; transition: all 0.2s ease-in-out;color:@pcolor;text-align: center;line-height: normal;}

    // &:focus {
    //     &::-webkit-input-placeholder {opacity:0;}
    //     &::-moz-placeholder          {opacity:0;}
    //     &:-moz-placeholder           {opacity:0;}
    //     &:-ms-input-placeholder      {opacity:0;}
    // }
}
.placeholderM(@pcolor: defualut) {
    &::-webkit-input-placeholder {opacity:1; -webkit-transition: all 0.2s ease-in-out; transition: all 0.2s ease-in-out;color:@pcolor;text-align: left;line-height: normal;}
    &::-moz-placeholder          {opacity:1;color:@pcolor;text-align: left;line-height: normal;}
    &:-moz-placeholder           {opacity:1;color:@pcolor;text-align: left;line-height: normal;}
    &:-ms-input-placeholder      {opacity:1; -o-transition: all 0.2s ease-in-out; transition: all 0.2s ease-in-out;color:@pcolor;text-align: left;line-height: normal;}

    // &:focus {
    //     &::-webkit-input-placeholder {opacity:0;}
    //     &::-moz-placeholder          {opacity:0;}
    //     &:-moz-placeholder           {opacity:0;}
    //     &:-ms-input-placeholder      {opacity:0;}
    // }
}
.placeholderSpecial(@pcolor: defualut) {
    &::-webkit-input-placeholder {opacity:1; -webkit-transition: all 0.15s ease-in-out; transition: all 0.15s ease-in-out;color:@pcolor;text-align: left;line-height: normal;}
    &::-moz-placeholder          {opacity:1;color:@pcolor;text-align: left;line-height: normal;}
    &:-moz-placeholder           {opacity:1;color:@pcolor;text-align: left;line-height: normal;}
    &:-ms-input-placeholder      {opacity:1; -o-transition: all 0.15s ease-in-out; transition: all 0.15s ease-in-out;color:@pcolor;text-align: left;line-height: normal;}

    // &:focus {
    //     &::-webkit-input-placeholder {opacity:0;}
    //     &::-moz-placeholder          {opacity:0;}
    //     &:-moz-placeholder           {opacity:0;}
    //     &:-ms-input-placeholder      {opacity:0;}
    // }
}
.placeholder2(@pcolor: defualut) {
    &::-webkit-input-placeholder {-webkit-transition: all 0.2s ease-in-out; transition: all 0.2s ease-in-out;color:@pcolor;line-height: normal;}
    &::-moz-placeholder          {color:@pcolor;line-height: normal;}
    &:-moz-placeholder           {color:@pcolor;line-height: normal;}
    &:-ms-input-placeholder      {-o-transition: all 0.2s ease-in-out; transition: all 0.2s ease-in-out;color:@pcolor;line-height: normal;}
}
