html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    outline: none;
}

body {
    font-size: 0;
    line-height: 1.4;
    font-weight: 500;
}

@media (min-device-width: 320px) and (max-device-width: @mw-m-up) {
    body {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-focus-ring-color: rgba(255, 255, 255, 0);
        outline: none;
        //-moz-user-select: none;
        //-khtml-user-select: none;
        //-webkit-user-select: none;
        //user-select: none;
        resize: none;
        text-size-adjust: none;
    }
}

ol,
ul {
    list-style: none;
}

sup {
    vertical-align: super;
}

sub {
    vertical-align: sub;
}

strong {
    font-weight: bold;
}

input,
textarea,
select,
button {
    outline: none;
    border: 0;
    padding: 0;
    margin: 0;
    font-family: inherit;
}

textarea {
    resize: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    max-width: 100%;
}
button {
    background-color: transparent;
    cursor: pointer;
}
nav {
    display: block;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:focus {
    outline: none;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.dibt {
    display: inline-block;

    zoom: 1;
    vertical-align: top;
}

.dibm {
    display: inline-block;

    zoom: 1;
    vertical-align: middle;
}

.wrap {
    margin-right: auto;
    margin-left: auto;
}

.all {
    display: table;
    width: 100%;
}

.nobr {
    white-space: pre;
}
input::-webkit-search-cancel-button {
    display: none;
}
