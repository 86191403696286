.page-loader {
    display: block;
    text-align: center;
    color: @red;
    padding: 0;
    img {
        display: block;
        margin: 0 auto;
    }
}
