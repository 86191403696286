.for-btn-load {
  width:100%;
  position:relative;
  display:inline-block;
  &:after {
    content:'';
    display:block;
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background-image: @ma-load-gif;
    background-repeat: no-repeat;
    background-position:center;
    background-size: auto 80%;
    z-index:5;
    opacity: 0;
    transform: scale(0);
    zoom: 1;
    transition: all 0.3s ease-in-out;
  }
  .btn {
    z-index:10;
  }
  .mButton {
    z-index:10;
  }
  .bbt-elem-close-inn {
    z-index:10;
  }
  &.loading {
    &:after {
      opacity: 1;
      transform: scale(1);
      z-index:15;
    }
    .btn {
      opacity: 0;
    }
    .mButton {
      opacity: 0;
    }
    .bbt-elem-close-inn {
      opacity: 0;
    }
    .for-btn-load-text {
      z-index:15;
      transform: scale(1);
      opacity: 1;
    }
  }

}
