@import "../variables";

p {
    margin-bottom: @offset-wrapper-s;

    &:last-child {
        margin-bottom: 0;
    }

    &:only-child {
        margin-bottom: @offset-wrapper-s;
    }
}

@media (min-width: @size-bp-s) {
    p {
        margin-bottom: @offset-wrapper-m;
    }
}
