:root {
    --white: #fff;
    --black: #000;
    --black20: #0003;
    --red: #f63f3f;
    --pink-dark: #ff6868;
    --pink: #fa8c8c;
    --pink-light: #ffc7c7;
    --grey-dark: #707070;
    --grey: #dadada;
    --grey-light: #f0f0f0;
    --grey-medium: #bbb;
    --blue: #68abc1;
    --blue-light: #96cee0;
    --black-light: #2a2a2a;
    --yellow-dark: #dab758;
    --yellow-medium: #efcb69;
    --yellow-light: #fff5c1;
    --green-stock-index: #88ab3f;
    --green-stock-index-light: #b0d267;
    --color-primary: var(--red);
    --color-text-primary: var(--black);
    --color-text-secondary: var(--grey-dark);
    --color-link-primary: var(--red);
    --color-bg-primary: var(--white);
    --color-bg-secondary: var(--grey-light);
    --color-error-primary: var(--red);
    --color-warning-primary: var(--yellow-light);
    --color-info-primary: var(--red);
    --color-index-light-red: #f16232;
    --color-index-yellow: #f6ae42;
    --size-font: 15px;
    --size-line-height: 1.5;
    --font-family: @mont;
    --size-bp-xs: 320px;
    --size-bp-s: 768px;
    --size-bp-m: 1000px;
    --size-bp-l: 1240px;
    --size-bp-xl: 1920px;
    --size-fix: var(--size-bp-xs);
    --size-fix2: 300px;
}
