body{
	font-family: @mont;
	width:100%;
    //min-width:@mw-s;
    background: var(--white);
	color: var(--color-text-primary);
	-webkit-font-smoothing: subpixel-antialiased;
	text-rendering:optimizeLegibility;
}

::selection {
	background:rgba(0,0,0,.8);
	color:@white;
}

html {
    min-height:100vh;
	body {
        min-height: 100vh;
		position: relative;
	}
}
section {
	width:100%;
}
a {
	cursor:pointer;
}
a[href^=tel] {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}
a[href^=skype] {
	color: inherit;
	text-decoration: none;
	cursor: default;
}

b, strong {
	font-weight:bold;
}
i {
	font-style: italic;
}
span.break {
	white-space:pre;
}
.wrap{
	width:@mw-s;
	padding-right: 10px;
	padding-left: 10px;
	&-s {
		width:@mw-s;
		padding-right: 10px;
		padding-left: 10px;
		margin:0 auto;
	}
	&-m {
		width:100%;
		padding-right:0;
		padding-left:0;
		margin:0;
	}
}
.hddn {
	display:none;
	opacity: 0;
	visibility: hidden;
}
.mw-s {
	display:block;
}
.mw-m {
	display:none;
}
.br-s {
	display: block;
}
.htext-s {
	display: inline;
}
.htext-m {
	display: none;
}
.blue__bg {
	background:#4ca2e4!important;
}
.yellow__bg {
	background:@orange!important;
}
.pink__bg {
	background:#ce71b9!important;
}
.green__bg {
	background:@green!important;
}
.lgreen__bg {
	background:#26b84b!important;
}
.non__bg {
	background:transparent!important;
}
