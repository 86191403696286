.haveEmail {
  &-link {
    text-align: center;
    a {
      font-family: @mont;
      color:@red;
      font-size: 13px;
      line-height: 1;
      font-weight: bold;
      .underline_dashed (@red, 90%, 1px, 0);
    }
  }

  @media (min-width: @mw-m-up) {
    html#desktop & {
      &-link {
        margin:12px 0 0 20px;
        text-align: left;
        a {
          font-size: 15px;
          transition: border-color 0.2s ease-in-out;
          border-width: 1px;
          &:hover {
            border-color:transparent;
          }
        }
      }
    }
  }
}
