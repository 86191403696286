@import "../variables";

.wrapper {
    min-width: @size-bp-xs - 20;
    padding: 0 16px;
    margin: 0 auto;

    &_desktop {
        padding: 0;
    }
}

@media (min-width: @size-bp-s) {
    .wrapper {
        //padding: 0 30px;
        &_desktop {
            padding: 0 16px;
        }
    }
}

@media (min-width: @size-bp-l) {
    .wrapper {
        max-width: @size-bp-l + 32;
        padding: 0 16px;

    }
}


//.wrapper {
//    min-width: @size-bp-xs - 20;
//    padding: 0 @offset-wrapper-xs;
//    margin: 0 auto;
//}
//
//@media (min-width: @size-bp-s) {
//    .wrapper {
//        padding: 0 @offset-wrapper-s;
//    }
//}
//
//@media (min-width: @size-bp-l) {
//    .wrapper {
//        max-width: @size-bp-l + (@offset-wrapper-l * 2);
//        padding: 0 @offset-wrapper-l;
//    }
//}
